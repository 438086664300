// Headings.tsx

import { Heading, HeadingProps } from '@chakra-ui/react';

export const H1 = (props: HeadingProps) => {
  return (
    <Heading
      as='h1'
      fontSize={{ base: '40px', md: '50px', lg: '65px' }}
      {...props}
    />
  );
};
export const H2 = (props: HeadingProps) => {
  return <Heading as='h2' fontSize={{ base: '30px', xl: '55px' }} {...props} />;
};
export const H3 = (props: HeadingProps) => {
  return <Heading as='h3' fontSize={{ base: '30px', lg: '45px' }} {...props} />;
};
export const H4 = (props: HeadingProps) => {
  return <Heading as='h4' fontSize={{ base: '25px', xl: '30px' }} {...props} />;
};
export const H5 = (props: HeadingProps) => {
  return <Heading as='h5' fontSize={{ base: '20px', xl: '25px' }} {...props} />;
};
export const H6 = (props: HeadingProps) => {
  return <Heading as='h6' fontSize={{ base: '15px', xl: '20px' }} {...props} />;
};
