export const innerLinks = {
  dimentDollar: '/diment-dollar',
  fees: '/fees',
  verify: '/verify',
  aboutUs: '/about-us',
  contactUs: '/contact-us',
  assets: '/assets',
  faq: '/faq',
  help: '/help',
  howItWorks: '/how-it-works',
  main: '/',
  networks: '/networks',
  social: '/social',
  transparency: '/transparency',
  partners: '/partners',
  news: '/news',
  blogs: '#',
  quant: '/quant',
  legalterms: '/legal-terms',
  whitepaperPdf: '/pdfs/DimentDollarWP.pdf',
  imprint: '/imprint',
};
