// Texts.tsx

import { Text, TextProps } from '@chakra-ui/react';

export const TextLarge = (props: TextProps) => {
  return (
    <Text fontSize={{ base: '16px', md: '18px', lg: '20px' }} {...props} />
  );
};

export const TextDefault = (props: TextProps) => {
  return (
    <Text fontSize={{ base: '15px', md: '16px', lg: '18px' }} {...props} />
  );
};

export const TextSmall = (props: TextProps) => {
  return <Text fontSize={{ base: '12px', md: '14px' }} {...props} />;
};
