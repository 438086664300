import { defineStyleConfig } from '@chakra-ui/react';

export const components = {
  Button: defineStyleConfig({
    defaultProps: { colorScheme: 'primary' },
    baseStyle: { rounded: 'full' },
  }),
  Input: defineStyleConfig({
    defaultProps: { colorScheme: 'primary' },
  }),
  Switch: defineStyleConfig({
    defaultProps: { colorScheme: 'primary' },
  }),
  Checkbox: defineStyleConfig({
    defaultProps: { colorScheme: 'primary' },
  }),
  Radio: defineStyleConfig({
    defaultProps: { colorScheme: 'primary' },
  }),
  Tooltip: defineStyleConfig({
    baseStyle: { borderRadius: 2, p: 2 },
  }),
  Progress: defineStyleConfig({
    defaultProps: { colorScheme: 'primary' },
  }),
};
