// BaseLayout/Header/MobileDrawer.tsx

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  IconButton,
  Stack,
  Box,
  Button,
  Flex,
} from '@chakra-ui/react';
import * as React from 'react';
import { HiBars3BottomRight } from 'react-icons/hi2';
import NavLinks from './NavLinks';
import { useTranslation } from 'next-i18next';
import BaseButton from '@/components/BaseButton';
import { colors } from '@/styles/theme/colors';
import Link from 'next/link';
import { outerLinks } from '@/utils/constants/outer-links';
import BuyToken from '@/components/BuyToken';
import { LoginIconSVG } from '@/assets/svgs';
import MobileNavLinks from './MobileNavLinks';

type MobileDrawerProps = {
  iconColor: string;
};

const MobileDrawer: React.FC<MobileDrawerProps> = ({ iconColor }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('main');

  return (
    <>
      <IconButton
        display={{ base: 'flex', lg: 'none' }}
        ref={btnRef}
        size={'sm'}
        rounded={'none'}
        aria-label='menu'
        onClick={onOpen}
        fontSize={'xl'}
        bg={colors.diment.lightBlueTransparent}
        icon={<HiBars3BottomRight />}
        color={iconColor}
        variant={'none'}
      />

      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          bgColor={'rgba(10, 75, 114, 0.5)'}
          backdropFilter={'blur(10px)'}
          color={'white'}
        >
          <DrawerCloseButton />
          <DrawerHeader />

          <DrawerBody mt={12}>
            <Stack spacing={8}>
              <Stack gap={8}>
                <MobileNavLinks onNavClick={onClose} />
              </Stack>
              {/* <Flex w={'full'} justify={'center'} gap={2}>
                <Link href={outerLinks.signIn}>
                  <Button
                    _hover={{
                      transform: 'translateY(-2px)',
                      cursor: 'pointer',
                    }}
                    transitionDuration={'0.3s'}
                    transitionTimingFunction={'ease-in-out'}
                    color={'white'}
                    fontSize={'sm'}
                    variant={'none'}
                    leftIcon={<LoginIconSVG />}
                  >
                    {t('layout.header.nav-links.4')}
                  </Button>
                </Link>
                <BuyToken
                  variant={'solid'}
                  fontSize={'sm'}
                  color={'white'}
                  bgColor={colors.diment.darkBlue}
                  buttonName={t('layout.header.join-waitlist.get-started')}
                />
              </Flex> */}
            </Stack>
          </DrawerBody>

          <DrawerFooter />
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MobileDrawer;
