export { default as BadgeSVG } from './badge.svg';
export { default as LimitsSVG } from './limits.svg';
export { default as TransactionSVG } from './transaction.svg';
export { default as ReportDocSVG } from './report-doc.svg';
export { default as OfficialChannelSVG } from './official-channel.svg';
export { default as UnOfficialChannelSVG } from './unofficial-channel.svg';
export { default as CheckIconSVG } from './check-icon.svg';
export { default as QuoteSVG } from './quote.svg';
export { default as LoginIconSVG } from './login.svg';
export { default as LoginDarkIconSVG } from './login-dark.svg';
export { default as ArrowUpRightSVG } from './arrow-up-right.svg';
export { default as BlueStarSVG } from './blue-star.svg';
