import { SimpleGrid, Stack } from '@chakra-ui/react';

import { footerNavs } from '../constants';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { H6 } from '@/components/Headings';
import { TextSmall } from '@/components/Texts';
import { colors } from '@/styles/theme/colors';

const QuickLinks = () => {
  const { t } = useTranslation('main');

  return (
    <SimpleGrid
      justifyItems={'center'}
      w='full'
      maxW={'6xl'}
      columns={{ base: 2, sm: 4, md: 4, lg: 2, xl: 4 }}
      gap={8}
    >
      {footerNavs.map((footerNav) => (
        <Stack key={footerNav.heading} spacing={4}>
          <H6>{t(footerNav.heading)}</H6>
          <Stack>
            {footerNav.links.map((link, index) =>
              link.isActive ? (
                <Link href={link.href} key={footerNav.heading + index}>
                  <TextSmall
                    fontWeight={500}
                    color={colors.diment.black}
                    _hover={{ color: 'black' }}
                    transitionDuration={'0.3s'}
                    transitionTimingFunction={'ease-in-out'}
                  >
                    {t(link.label)}
                  </TextSmall>
                </Link>
              ) : (
                <TextSmall
                  cursor={'pointer'}
                  key={footerNav.heading + index}
                  color={colors.diment.grey}
                  _hover={{ color: 'black' }}
                  transitionDuration={'0.3s'}
                  transitionTimingFunction={'ease-in-out'}
                  userSelect={'none'}
                >
                  {t(link.label)}
                </TextSmall>
              ),
            )}
          </Stack>
        </Stack>
      ))}
    </SimpleGrid>
  );
};

export default QuickLinks;
