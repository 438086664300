import { Container, type ContainerProps } from '@chakra-ui/react';

const BaseContainer = ({ children, ...props }: ContainerProps) => {
  return (
    <Container
      py={{ base: 16, lg: 24 }}
      px={{ base: 4, lg: 8 }}
      maxW={'8xl'}
      {...props}
    >
      {children}
    </Container>
  );
};

export default BaseContainer;
