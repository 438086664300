// BaseLayout/index.tsx

import * as React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Box } from '@chakra-ui/react';

const BaseLayout = ({ children }: React.PropsWithChildren) => {
  return (
    <>
      <Header />
      <Box mt={-24}>{children}</Box>
      <Footer />
    </>
  );
};

export default BaseLayout;
