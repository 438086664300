// BaseLayout/Header/index.tsx

import { Box, Button, Flex } from '@chakra-ui/react';
import Image from 'next/image';
import NavLinks from './NavLinks';
import { useWindowScroll } from 'react-use';
import MobileDrawer from './MobileDrawer';
import Link from 'next/link';
import BaseContainer from '@/components/BaseContainer';
import { innerLinks } from '@/utils/constants/inner-links';
import { useTranslation } from 'next-i18next';
import { outerLinks } from '@/utils/constants/outer-links';
import { LoginDarkIconSVG, LoginIconSVG } from '@/assets/svgs';
import { colors } from '@/styles/theme/colors';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import BuyToken from '@/components/BuyToken';

const Header = () => {
  const router = useRouter();
  const { y } = useWindowScroll();
  const { t } = useTranslation('main');
  const whitePages = [
    '/contact-us',
    '/imprint',
    '/legal-terms',
    '/404',
    '/verify',
  ];
  const isWhitePage = whitePages.includes(router.pathname);

  const [headerSettings, setHeaderSettings] = useState({
    textColor: 'white',
    logoSrc: '/images/logos/diment-logo-white.svg',
    loginIcon: <LoginIconSVG />,
    bgColor: 'rgba(255, 255, 255, 0.15)',
  });

  useEffect(() => {
    const isAtTop = y === 0;
    const shouldUseWhitePageSettings = isWhitePage && isAtTop;

    setHeaderSettings({
      textColor: shouldUseWhitePageSettings ? colors.diment.darkBlue : 'white',
      logoSrc: shouldUseWhitePageSettings
        ? '/images/logos/diment-logo-dark-blue.svg'
        : '/images/logos/diment-logo-white.svg',
      loginIcon: shouldUseWhitePageSettings ? (
        <LoginDarkIconSVG />
      ) : (
        <LoginIconSVG />
      ),
      bgColor: y > 0 ? 'rgba(10, 75, 114, 0.5)' : 'rgba(255, 255, 255, 0.15)',
    });
  }, [y, isWhitePage]);

  const iconColor = headerSettings.textColor;

  return (
    <Box
      position={'sticky'}
      px={4}
      top={0}
      zIndex={100}
      bgColor={headerSettings.bgColor}
      shadow={y ? 'md' : 'none'}
      transitionDuration={'0.5s'}
      backdropFilter={'blur(10px)'}
    >
      <BaseContainer py={3} maxW={'8xl'} px={0} transitionDuration={'0.5s'}>
        <Flex align={'center'} justify={'space-between'}>
          {/* w={'300px'} */}
          <Flex minH={10} align={'center'}>
            <Box w={130}>
              <Link href={innerLinks.main}>
                <Image
                  priority
                  src={headerSettings.logoSrc}
                  style={{ objectFit: 'contain' }}
                  alt='logo'
                  width={790}
                  height={182}
                />
              </Link>
            </Box>
          </Flex>

          <Flex
            align={'center'}
            display={{ base: 'none', lg: 'flex' }}
            w={'full'}
            gap={{ lg: 8, xl: 12 }}
            pr={{ base: 0, xl: 32 }}
            justify={{ base: 'end', xl: 'center' }}
            color={headerSettings.textColor}
          >
            <NavLinks />
          </Flex>

          {/* <Flex
            display={{ base: 'none', lg: 'flex' }}
            justify={'end'}
            gap={4}
            w={'300px'}
          >
            <Link href={outerLinks.signIn}>
              <Button
                _hover={{
                  transform: 'translateY(-2px)',
                  cursor: 'pointer',
                }}
                transitionDuration={'0.3s'}
                transitionTimingFunction={'ease-in-out'}
                color={headerSettings.textColor}
                fontSize={'sm'}
                variant={'none'}
                leftIcon={headerSettings.loginIcon}
              >
                {t('layout.header.nav-links.4')}
              </Button>
            </Link>

            <BuyToken
              variant={'solid'}
              fontSize={'sm'}
              color={'white'}
              bgColor={colors.diment.darkBlue}
              buttonName={t('layout.header.join-waitlist.get-started')}
            />
          </Flex> */}

          <MobileDrawer iconColor={iconColor} />
        </Flex>
      </BaseContainer>
    </Box>
  );
};

export default Header;
