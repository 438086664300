// CookieConsent.tsx

import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useBreakpointValue,
  ModalFooter,
  Divider,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
} from '@chakra-ui/react';
import BaseButton from './BaseButton';
import { colors } from '@/styles/theme/colors';
import { TextSmall } from './Texts';

const CookieConsent = () => {
  const [isNecessary, setIsNecessary] = useState(true);
  const [isFunctional, setIsFunctional] = useState(false);
  const [isAnalytics, setIsAnalytics] = useState(false);
  const [isPerformance, setIsPerformance] = useState(false);
  const [isAdvertisement, setIsAdvertisement] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCustomizeOpen, setIsCustomizeOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsOpen(true);
    }
  }, []);

  const handleAcceptAll = () => {
    document.cookie = 'cookies_accepted=true; path=/;';
    localStorage.setItem('cookieConsent', 'true');
    setIsOpen(false);
    setIsCustomizeOpen(false);
  };

  const handleRejectAll = () => {
    document.cookie = 'cookies_accepted=false; path=/;';
    localStorage.setItem('cookieConsent', 'true');
    setIsOpen(false);
    setIsCustomizeOpen(false);
  };

  const handleSavePreferences = () => {
    const cookieConsent = JSON.stringify({
      necessary: isNecessary,
      functional: isFunctional,
      analytics: isAnalytics,
      performance: isPerformance,
      advertisement: isAdvertisement,
    });
    document.cookie = `cookies_accepted=${cookieConsent}; path=/;`;
    localStorage.setItem('cookieConsent', 'true');
    setIsOpen(false);
    setIsCustomizeOpen(false);
  };

  const handleCustomize = () => {
    setIsCustomizeOpen(true);
    setIsOpen(false);
  };

  const handleCustomizeClose = () => {
    setIsCustomizeOpen(false);
    setIsOpen(true);
  };

  const left = useBreakpointValue({ base: 0, md: '40px' });
  const bottom = useBreakpointValue({ base: 0, md: '40px' });
  const transform = useBreakpointValue({
    base: 'translate(-50%, -50%)',
    md: undefined,
  });

  const shortDescription = (
    <TextSmall fontSize={'sm'} pb={4}>
      We use cookies to help you navigate efficiently and perform certain
      functions. You will find detailed information about all cookies under each
      consent category below. The cookies that are categorized as
      &quot;Necessary&quot; are stored on your browser as they are essential for
      enabling the basic functionalities of the site.{' '}
      <Button
        onClick={() => setShowMore(true)}
        variant='link'
        color={colors.diment.darkBlue}
        fontSize={'sm'}
      >
        Show more
      </Button>
    </TextSmall>
  );

  const longDescription = (
    <TextSmall fontSize={'sm'} pb={4}>
      We use cookies to help you navigate efficiently and perform certain
      functions. You will find detailed information about all cookies under each
      consent category below. The cookies that are categorized as
      &quot;Necessary&quot; are stored on your browser as they are essential for
      enabling the basic functionalities of the site.{' '}
      <TextSmall>
        We also use third-party cookies that help us analyze how you use this
        website, store your preferences, and provide the content and
        advertisements that are relevant to you. These cookies will only be
        stored in your browser with your prior consent.
      </TextSmall>
      <TextSmall>
        You can choose to enable or disable some or all of these cookies but
        disabling some of them may affect your browsing experience.{' '}
        <Button
          onClick={() => setShowMore(false)}
          variant='link'
          color={colors.diment.darkBlue}
          fontSize={'sm'}
        >
          Show less
        </Button>
      </TextSmall>
    </TextSmall>
  );

  return (
    <>
      <Popover
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        closeOnBlur={false}
        closeOnEsc={false}
        autoFocus={false}
      >
        <PopoverContent
          position='fixed'
          left={left}
          bottom={bottom}
          transform={transform}
          w='full'
          maxW={{ base: 'full', md: '440px' }}
          fontSize={'sm'}
          p={2}
        >
          <PopoverHeader fontSize={'lg'} fontWeight={700} borderBottom='none'>
            We value your privacy
          </PopoverHeader>
          <PopoverBody py={2}>
            <TextSmall>
              We use cookies to enhance your browsing experience, serve
              personalized ads or content, and analyze our traffic. By clicking
              &quot;Accept All&quot;, you consent to our use of cookies.
            </TextSmall>
          </PopoverBody>
          <PopoverFooter borderTop='none'>
            <Flex
              w={'full'}
              direction={{ base: 'column-reverse', sm: 'row' }}
              justify='center'
              align='center'
              gap={2}
              fontSize={12}
            >
              <BaseButton
                py={5}
                w={'full'}
                size={'sm'}
                onClick={handleCustomize}
                variant={'outline'}
                rounded={'none'}
                color={colors.diment.darkBlue}
              >
                Customize
              </BaseButton>
              <BaseButton
                py={5}
                w={'full'}
                size={'sm'}
                onClick={handleRejectAll}
                variant={'outline'}
                rounded={'none'}
                color={colors.diment.darkBlue}
              >
                Reject All
              </BaseButton>
              <BaseButton
                py={5}
                w={'full'}
                size={'sm'}
                onClick={handleAcceptAll}
                bgColor={colors.diment.darkBlue}
                rounded={'none'}
              >
                Accept All
              </BaseButton>
            </Flex>
          </PopoverFooter>
        </PopoverContent>
      </Popover>

      <Modal
        isOpen={isCustomizeOpen}
        onClose={handleCustomizeClose}
        isCentered={true}
        trapFocus={false}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        size={'2xl'}
      >
        <ModalOverlay />
        <ModalContent
          h={{ base: '75vh', md: '50vh' }}
          position='fixed'
          m='0'
          fontSize={'sm'}
        >
          <ModalHeader>Customize Consent Preferences</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody pt={4} px={6} pb={4} overflow={'auto'}>
            {showMore ? longDescription : shortDescription}
            <Accordion allowToggle>
              <AccordionItem>
                <AccordionButton>
                  <Box flex='1' textAlign='left'>
                    Necessary (Always Active)
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <TextSmall mb={2}>
                    Necessary cookies are required to enable the basic features
                    of this site, such as providing secure log-in or adjusting
                    your consent preferences. These cookies do not store any
                    personally identifiable data.
                  </TextSmall>
                  <Checkbox
                    isChecked={isNecessary}
                    isDisabled
                    onChange={(e) => setIsNecessary(e.target.checked)}
                  >
                    Necessary
                  </Checkbox>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex='1' textAlign='left'>
                    Functional
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <TextSmall mb={2}>
                    Functional cookies help perform certain functionalities like
                    sharing the content of the website on social media
                    platforms, collecting feedback, and other third-party
                    features.
                  </TextSmall>
                  <Checkbox
                    isChecked={isFunctional}
                    onChange={(e) => setIsFunctional(e.target.checked)}
                  >
                    Functional
                  </Checkbox>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex='1' textAlign='left'>
                    Analytics
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <TextSmall mb={2}>
                    Analytical cookies are used to understand how visitors
                    interact with the website. These cookies help provide
                    information on metrics such as the number of visitors,
                    bounce rate, traffic source, etc.
                  </TextSmall>
                  <Checkbox
                    isChecked={isAnalytics}
                    onChange={(e) => setIsAnalytics(e.target.checked)}
                  >
                    Analytics
                  </Checkbox>
                  <Box mt={4} pl={4} borderLeft='2px' borderColor='gray.200'>
                    <TextSmall fontWeight='bold'>_ga</TextSmall>
                    <TextSmall fontSize='sm'>
                      Duration: 1 year 1 month 4 days
                    </TextSmall>
                    <TextSmall fontSize='sm'>
                      Description: Google Analytics sets this cookie to
                      calculate visitor, session and campaign data and track
                      site usage for the site&apos;s analytics report. The
                      cookie stores information anonymously and assigns a
                      randomly generated number to recognize unique visitors.
                    </TextSmall>
                    <TextSmall mt={4} fontWeight='bold'>
                      _ga_*
                    </TextSmall>
                    <TextSmall fontSize='sm'>
                      Duration: 1 year 1 month 4 days
                    </TextSmall>
                    <TextSmall fontSize='sm'>
                      Description: Google Analytics sets this cookie to store
                      and count page views.
                    </TextSmall>
                  </Box>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex='1' textAlign='left'>
                    Performance
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <TextSmall mb={2}>
                    Performance cookies are used to understand and analyze the
                    key performance indexes of the website which helps in
                    delivering a better user experience for the visitors.
                  </TextSmall>
                  <Checkbox
                    isChecked={isPerformance}
                    onChange={(e) => setIsPerformance(e.target.checked)}
                  >
                    Performance
                  </Checkbox>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex='1' textAlign='left'>
                    Advertisement
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <TextSmall mb={2}>
                    Advertisement cookies are used to provide visitors with
                    customized advertisements based on the pages you visited
                    previously and to analyze the effectiveness of the ad
                    campaigns.
                  </TextSmall>
                  <Checkbox
                    isChecked={isAdvertisement}
                    onChange={(e) => setIsAdvertisement(e.target.checked)}
                  >
                    Advertisement
                  </Checkbox>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </ModalBody>
          <ModalFooter>
            <Flex
              w={'full'}
              direction={{ base: 'column-reverse', md: 'row' }}
              justify='center'
              gap={2}
              fontSize={12}
            >
              <BaseButton
                py={5}
                w={'full'}
                size={'sm'}
                onClick={handleRejectAll}
                variant={'outline'}
                rounded={'none'}
                color={colors.diment.darkBlue}
              >
                Reject All
              </BaseButton>
              <BaseButton
                py={5}
                w={'full'}
                size={'sm'}
                onClick={handleSavePreferences}
                variant={'outline'}
                rounded={'none'}
                color={colors.diment.darkBlue}
              >
                Save My Preferences
              </BaseButton>
              <BaseButton
                py={5}
                w={'full'}
                size={'sm'}
                onClick={handleAcceptAll}
                bgColor={colors.diment.darkBlue}
                rounded={'none'}
              >
                Accept All
              </BaseButton>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CookieConsent;
