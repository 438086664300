// styles/theme/colors.ts

export const colors = {
  primary: {
    '50': '#f3f8fb',
    '100': '#cde4f0',
    '200': '#a2cbe3',
    '300': '#6caed2',
    '400': '#4d9cc8',
    '500': '#2184bb',
    '600': '#006eab',
    '700': '#00598a',
    '800': '#004b75',
    '900': '#003755',
  },
  diment: {
    darkBlue: '#0a4b72',
    blue: '#0072B1',
    lightBlue: '#64C8FF',
    lightBlueTransparent: 'rgba(100, 200, 255, 0.15)',
    lighterBlueTransparent: 'rgba(100, 200, 255, 0.1)',
    black: '#303030',
    grey: '#646464',
    lightGrey: '#F8F8F8',
  },
  gradients: {
    darkBlue: 'linear-gradient(135deg, #0072B1 0%, #0A4B72 100%)',
    lightBlue: 'linear-gradient(135deg, #B1E3FF 0%, #64C8FF 100%)',
  },
};
