import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';

const BaseButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button
      fontWeight={700}
      borderRadius={'10px'}
      _hover={{
        transform: 'translateY(-2px)',
        shadow: 'xl',
      }}
      transitionDuration={'0.3s'}
      transitionTimingFunction={'ease-in-out'}
      {...props}
    >
      {children}
    </Button>
  );
};

export default BaseButton;
