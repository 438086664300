import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Flex,
} from '@chakra-ui/react';
import Link from 'next/link';
import React, { useMemo } from 'react';
import { headerNavLinks } from '@/containers/BaseLayout/constants';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { colors } from '@/styles/theme/colors';
import Image from 'next/image';

type MobileNavLinksProps = { onNavClick?: () => void };

const MobileNavLinks = ({ onNavClick }: MobileNavLinksProps) => {
  const { t } = useTranslation('main');
  const router = useRouter();

  const defaultIndex = useMemo(() => {
    const openIndexes: number[] = [];
    headerNavLinks.forEach((item, index) => {
      if (item.subLinks) {
        const isSubLinkActive = item.subLinks.some(
          (subItem) => router.pathname === subItem.href,
        );
        if (isSubLinkActive) {
          openIndexes.push(index);
        }
      }
    });
    return openIndexes;
  }, [router.pathname]);

  return (
    <Accordion
      allowToggle
      defaultIndex={defaultIndex}
      maxHeight='75vh'
      overflowY='auto'
    >
      {headerNavLinks.map((item, index) => (
        <AccordionItem key={index} border='none'>
          <AccordionButton
            _expanded={{ bg: colors.diment.lightBlue, color: 'white' }}
            justifyContent='space-between'
            alignItems='center'
          >
            <Box
              py={1}
              flex='1'
              textAlign='left'
              fontWeight={router.pathname === item.href ? '800' : '500'}
            >
              {item.subLinks ? (
                <Text>{t(item.label)}</Text>
              ) : (
                <Link href={item.href} passHref>
                  <Text onClick={onNavClick}>{t(item.label)}</Text>
                </Link>
              )}
            </Box>

            {item.subLinks ? <AccordionIcon /> : null}
          </AccordionButton>

          {item.subLinks && (
            <AccordionPanel pb={4}>
              {item.subLinks.map((subItem, subIndex) => (
                <Box key={subIndex} py={2}>
                  <Link href={subItem.href} passHref>
                    <Flex
                      align='center'
                      onClick={onNavClick}
                      pl={4}
                      gap={4}
                      fontWeight={
                        router.pathname === subItem.href ? '800' : '500'
                      }
                      color={
                        router.pathname === subItem.href
                          ? colors.diment.lightBlue
                          : 'inherit'
                      }
                    >
                      {'icon' in subItem && subItem.icon && (
                        <Box w={'full'} maxW={6}>
                          <Image
                            width={73}
                            height={73}
                            src={subItem.icon}
                            alt='icon'
                          />
                        </Box>
                      )}
                      <Text>{t(subItem.label)}</Text>
                    </Flex>
                  </Link>
                </Box>
              ))}
            </AccordionPanel>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default MobileNavLinks;
