import { Divider, Flex, Stack, SimpleGrid } from '@chakra-ui/react';
import QuickLinks from './QuickLinks';
import Socials from '@/components/Socials';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { innerLinks } from '@/utils/constants/inner-links';
import Image from 'next/image';
import { TextSmall } from '@/components/Texts';
import { colors } from '@/styles/theme/colors';

const Footer = () => {
  const { t } = useTranslation('main');

  return (
    <Stack
      spacing={{ base: 16, lg: 28 }}
      align={'center'}
      pt={{ base: 16, lg: 24 }}
      pb={8}
      px={{ base: 4, md: 12 }}
      bg={colors.diment.lightGrey}
    >
      <Flex
        w='full'
        maxW={'8xl'}
        gap={{ base: 12, lg: 0, xl: 20 }}
        align={{ base: 'center', lg: 'center' }}
        direction={{ base: 'column', lg: 'row' }}
      >
        <Stack
          align={{ base: 'center', lg: 'start' }}
          gap={8}
          w={'full'}
          maxW={'sm'}
        >
          <Stack spacing={4} align={{ base: 'center', lg: 'start' }}>
            <Link href={innerLinks.main}>
              <Image
                src={`/images/logos/diment-logo-black.svg`}
                width={136}
                height={31}
                style={{ objectFit: 'contain' }}
                alt='diment dollar logo'
              />
            </Link>
            <Flex
              w={'full'}
              justify={'center'}
              textAlign={{ base: 'center', lg: 'start' }}
            >
              <TextSmall
                fontWeight={500}
                width={'full'}
                maxW={{ base: 260, md: 300 }}
              >
                {t('layout.footer.goodbye')}
              </TextSmall>
            </Flex>
          </Stack>
          <Socials />
        </Stack>

        <QuickLinks />
      </Flex>

      <Stack w={'full'} maxW={'8xl'}>
        <Flex w='full'>
          <Divider borderColor={'rgba(100, 100, 100, 0.25)'} />
        </Flex>
        <SimpleGrid
          w='full'
          columns={{ base: 1, md: 2 }}
          gap={8}
          color={colors.diment.grey}
        >
          <Stack textAlign={{ base: 'center', md: 'left' }}>
            <TextSmall fontSize={'10px'}>
              {t('layout.footer.warning')}
            </TextSmall>
          </Stack>
          <Stack w={'full'} textAlign={{ base: 'center', md: 'right' }}>
            <TextSmall fontSize={'10px'}>
              {t('layout.footer.all-rights', {
                year: new Date().getFullYear(),
              })}
            </TextSmall>
          </Stack>
        </SimpleGrid>
      </Stack>
    </Stack>
  );
};

export default Footer;
