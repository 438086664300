import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';
import { headerNavLinks } from '@/containers/BaseLayout/constants';
import { useTranslation } from 'next-i18next';
import { colors } from '@/styles/theme/colors';
import Image from 'next/image';
import { useRouter } from 'next/router';

const NavLinks = () => {
  const { t } = useTranslation('main');
  const router = useRouter();

  const isActiveMenu = (item: any) => {
    if (router.pathname === item.href) {
      return true;
    }
    if (item.subLinks) {
      return item.subLinks.some(
        (subItem: any) => router.pathname === subItem.href,
      );
    }
    return false;
  };

  const isActiveSubMenu = (child: any) => {
    return router.pathname === child.href;
  };

  return (
    <Box
      display={{ base: 'none', lg: 'block' }}
      fontWeight={'bold'}
      mr={{ base: 5, xl: 0 }}
    >
      <Flex align='center'>
        {headerNavLinks.map((item, index) => (
          <Popover trigger='hover' key={index}>
            <PopoverTrigger>
              <Link href={item.href}>
                <Box
                  textAlign={'end'}
                  py={2}
                  px={6}
                  cursor='pointer'
                  position='relative'
                >
                  <Flex
                    w={'full'}
                    justify={'space-between'}
                    align={'center'}
                    gap={4}
                    fontWeight={isActiveMenu(item) ? '800' : '500'}
                    position={'relative'}
                    _after={
                      isActiveMenu(item)
                        ? {
                            content: '""',
                            position: 'absolute',
                            bottom: '-8px',
                            left: 0,
                            height: '2px',
                            width: '100%',
                            bg: colors.diment.lightBlue,
                          }
                        : {}
                    }
                  >
                    <Text color={'inherit'}>{t(item.label)}</Text>
                  </Flex>
                </Box>
              </Link>
            </PopoverTrigger>
            {item.subLinks && (
              <PopoverContent
                w={'full'}
                minW={200}
                border='none'
                _focus={{ boxShadow: 'none' }}
                rounded={'xl'}
                py={1}
                px={'14px'}
              >
                <PopoverArrow />
                <Stack py={2} gap={2}>
                  {item.subLinks.map((child, childIndex) => (
                    <Link key={childIndex} href={child.href}>
                      <Flex
                        align='center'
                        justify='start'
                        bgColor={'white'}
                        fontWeight={isActiveSubMenu(child) ? '800' : '500'}
                        color={colors.diment.darkBlue}
                        py={2}
                        px={4}
                        gap={4}
                      >
                        {'icon' in child && child.icon && (
                          <Box w={'full'} maxW={5}>
                            <Image
                              width={73}
                              height={73}
                              src={child.icon}
                              alt='icon'
                            />
                          </Box>
                        )}
                        <Text _hover={{ filter: 'grayscale(100%)' }}>
                          {t(child.label)}
                        </Text>
                      </Flex>
                    </Link>
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        ))}
      </Flex>
    </Box>
  );
};

export default NavLinks;
