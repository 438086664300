// pages/_app.tsx

import '@/styles/globals.css';
import { ChakraProvider, createLocalStorageManager } from '@chakra-ui/react';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { colorModeLocalKey, theme } from '@/styles/theme';
import BaseLayout from '@/containers/BaseLayout';
import { QueryClient, QueryClientProvider } from 'react-query';
import React, { useEffect } from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import { DefaultSeo } from 'next-seo';
import SEO from '../../next-seo.config.js';
import CookieConsent from '@/components/CookieConsent';

const colorModeManager = createLocalStorageManager(colorModeLocalKey);

function App({ Component, pageProps }: AppProps) {
  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     Intercom({
  //       app_id: 'h5n3hi2t',
  //     });
  //     return () => {
  //       (Intercom as any)('shutdown');
  //     };
  //   }
  // }, []);

  if (typeof window !== 'undefined') {
    window.console.log = () => {};
    window.console.error = () => {};
    window.console.warn = () => {};
    window.console.info = () => {};
    window.console.debug = () => {};
  }

  const [queryClient] = React.useState(() => new QueryClient());

  return (
    <>
      <ChakraProvider colorModeManager={colorModeManager} theme={theme}>
        <QueryClientProvider client={queryClient}>
          <BaseLayout>
            <DefaultSeo {...SEO} />
            <Component {...pageProps} />
            <CookieConsent />
          </BaseLayout>
        </QueryClientProvider>
      </ChakraProvider>
    </>
  );
}

export default appWithTranslation(App);
