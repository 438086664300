// BaseLayout/constant.ts

import { innerLinks } from '@/utils/constants/inner-links';
import { NavLink } from '@/utils/constants/types';

export const footerNavs = [
  {
    heading: 'layout.footer.link-headings.0',
    links: [
      {
        label: 'layout.footer.links.diment.0',
        href: innerLinks.dimentDollar,
        isActive: true,
      },
      {
        label: 'layout.footer.links.diment.1',
        href: innerLinks.howItWorks,
        isActive: true,
      },
      {
        label: 'layout.footer.links.diment.2',
        href: innerLinks.transparency,
        isActive: true,
      },
      // {
      //   label: 'layout.footer.links.diment.5',
      //   href: innerLinks.fees,
      //   isActive: false,
      // },
    ],
  },
  {
    heading: 'layout.footer.link-headings.1',
    links: [
      // {
      //   label: 'layout.footer.links.company.0',
      //   href: innerLinks.aboutUs,
      //   isActive: true,
      // },
      {
        label: 'layout.footer.links.company.1',
        href: innerLinks.contactUs,
        isActive: true,
      },
      {
        label: 'layout.footer.links.company.2',
        href: innerLinks.legalterms,
        isActive: true,
      },
      {
        label: 'layout.footer.links.company.4',
        href: innerLinks.imprint,
        isActive: true,
      },
      {
        label: 'layout.footer.links.company.3',
        href: innerLinks.assets,
        isActive: true,
      },
    ],
  },
  {
    heading: 'layout.footer.link-headings.2',
    links: [
      // { label: 'layout.footer.links.resources.0', href: innerLinks.news },

      {
        label: 'layout.footer.links.resources.2',
        href: innerLinks.social,
        isActive: true,
      },
      {
        label: 'layout.footer.links.diment.3',
        href: innerLinks.whitepaperPdf,
        isActive: true,
      },
      // {
      //   label: 'layout.footer.links.diment.4',
      //   href: innerLinks.blogs,
      //   isActive: false,
      // },
      // {
      //   label: 'layout.footer.links.diment.6',
      //   href: innerLinks.quant,
      //   isActive: false,
      // },
    ],
  },
  {
    heading: 'layout.footer.link-headings.3',
    links: [
      {
        label: 'layout.footer.links.solutions.0',
        href: innerLinks.help,
        isActive: false,
      },
      {
        label: 'layout.footer.links.solutions.1',
        href: innerLinks.verify,
        isActive: true,
      },
      {
        label: 'Partners',
        href: innerLinks.partners,
        isActive: true,
      },
      {
        label: 'layout.footer.links.resources.1',
        href: innerLinks.faq,
        isActive: false,
      },
    ],
  },
];

export const headerNavLinks: NavLink[] = [
  { label: 'layout.header.nav-links.0', href: '/', isActive: true },
  {
    label: 'layout.header.nav-links.1',
    href: innerLinks.dimentDollar,
    isActive: true,
  },
  {
    label: 'layout.header.nav-links.2',
    href: innerLinks.howItWorks,
    isActive: true,
  },
  {
    label: 'layout.header.nav-links.3',
    href: innerLinks.transparency,
    isActive: true,
  },
  {
    label: 'layout.header.nav-links.4',
    href: innerLinks.partners,
    isActive: true,
  },

  // {
  //   label: 'layout.header.nav-links.3',
  //   href: innerLinks.quant,
  //   isActive: false,
  // },
  //.
];
